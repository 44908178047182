import { FunctionComponent } from "react";
import {
  AccordionItem,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text
} from "@bps/fluent-ui";
import { PreviewSubscriptionProductData } from "./preview-subscription.types";
import {
  useChildTenants,
  useSalesProduct
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import { useParams } from "react-router-dom";

interface PreviewSubscriptionAccordionItemProps {
  data: PreviewSubscriptionProductData;
}
export const PreviewSubscriptionAccordionItem: FunctionComponent<PreviewSubscriptionAccordionItemProps> = ({
  data
}) => {
  const { tenantId } = useParams<{ tenantId: string }>();

  const productQuery = useSalesProduct(data.salesProductId);
  const childTenantQuery = useChildTenants(tenantId!);

  return (
    <AccordionItem
      title=""
      extendedByDefault={true}
      onRenderTitle={() => (
        <QueryStateIndicator {...productQuery}>
          {product => {
            return (
              <Stack tokens={{ childrenGap: 2 }}>
                <Text bold>{product.name}</Text>
                <Text>{product.productFamily}</Text>
              </Stack>
            );
          }}
        </QueryStateIndicator>
      )}
      styles={{
        heading: { padding: 4 }
      }}
    >
      <QueryStateIndicator {...childTenantQuery}>
        {childTenants => {
          return (
            <Stack tokens={{ childrenGap: 8 }}>
              <Grid
                templateColumns="repeat(2, 1fr)"
                childrenTokens={{ columnGap: 8 }}
              >
                <Heading>Child tenant name</Heading>
                <Heading>Quantity</Heading>
              </Grid>
              {data.childTenants.map(item => {
                const childTenantName = childTenants.find(
                  ct => ct.id === item.childTenantId
                )?.name;

                return (
                  <Grid
                    key={item.childTenantId}
                    templateColumns="repeat(2, 1fr)"
                    childrenTokens={{ columnGap: 8 }}
                  >
                    <GridItem>{childTenantName}</GridItem>
                    <GridItem>{item.quantity}</GridItem>
                  </Grid>
                );
              })}
            </Stack>
          );
        }}
      </QueryStateIndicator>
    </AccordionItem>
  );
};
