import React, { FunctionComponent, useMemo } from "react";

import { Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { FormDatePicker } from "@components/form/fields/FormDatePicker";
import { pinLengthSchema } from "@components/form/fields/FormPin.validator";
import { FormPinField } from "@components/form/fields/FormPinField";
import { FormTextField } from "@components/form/fields/FormTextField";
import { Form } from "@components/form/Form";
import { Validator } from "@components/form/validation/Validator";
import {
  useAddRedirectLink,
  useUpdatedRedirectLink
} from "@libs/api/gateways/plt-redirect/plt-redirect-gateway.hooks";

import {
  AddRedirectLinkFormProps,
  RedirectLinkFormValues
} from "./AddRedirectLinkDialog.types";

const validator = new Validator<RedirectLinkFormValues>();
export const AddRedirectLinkForm: FunctionComponent<AddRedirectLinkFormProps> = ({
  onDismiss,
  redirectLink
}) => {
  const isEdit = !!redirectLink;

  const {
    error: addError,
    mutateAsync: addRedirectLink
  } = useAddRedirectLink();

  const {
    error: editError,
    mutateAsync: editRedirectLink
  } = useUpdatedRedirectLink();

  const defaultValues: RedirectLinkFormValues = useMemo(() => {
    if (isEdit && redirectLink) {
      return {
        redirectUrl: redirectLink.redirectUrl,
        notAfterUtc: redirectLink.notAfterUtc
          ? DateTime.fromISO(redirectLink.notAfterUtc).toJSDate()
          : undefined,
        notBeforeUtc: redirectLink.notBeforeUtc
          ? DateTime.fromISO(redirectLink.notBeforeUtc).toJSDate()
          : undefined
      };
    }
    return {
      redirectUrl: "",
      notAfterUtc: new Date(),
      notBeforeUtc: undefined,
      prefix: ""
    };
  }, [isEdit, redirectLink]);

  const onSubmit = async (values: RedirectLinkFormValues) => {
    const { notAfterUtc, notBeforeUtc, ...restValues } = values;
    const convertedDates = {
      notBeforeUtc: notBeforeUtc ? notBeforeUtc.toISOString() : undefined,
      notAfterUtc: notAfterUtc ? notAfterUtc.toISOString() : undefined
    };

    try {
      if (redirectLink) {
        const payload = { ...redirectLink, ...restValues, ...convertedDates };
        await editRedirectLink(payload);
      } else {
        if (values.prefix) {
          const payload = {
            ...restValues,
            ...convertedDates,
            prefix: values.prefix
          };
          await addRedirectLink(payload);
        } else {
          throw Error("Prefix is required.");
        }
      }
      onDismiss();
    } catch (e) {
      return e;
    }
  };

  return (
    <Form<RedirectLinkFormValues>
      onSubmit={onSubmit}
      validate={values =>
        validator.validateWithParse(values, {
          redirectUrl: validator.custom().predicate({
            when: () => !isEdit,
            then: validator.string().required()
          }),
          prefix: validator.custom().predicate({
            when: () => !isEdit,
            then: validator.string().required()
          }),
          notAfterUtc: validator.date().required(),
          pin: validator
            .custom()
            .predicate({ when: () => !isEdit, then: pinLengthSchema })
        })
      }
      defaultValues={defaultValues}
      error={addError ?? editError}
    >
      <FormTextField name="redirectUrl" label="Redirect url" required />
      {!isEdit && <FormTextField name="prefix" label="Prefix" required />}
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <FormDatePicker name="notBeforeUtc" label="Date before" />
        <FormDatePicker name="notAfterUtc" label="Date after" required />
      </Stack>
      {!isEdit && <FormPinField name="pin" />}
    </Form>
  );
};
