import React, { FunctionComponent, useRef } from "react";
import { useNavigate } from "react-router";

import { Form } from "@components/form/Form";
import {
  AddSubscriptionDto,
  CustomerProductsDto,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  useAddCustomerSubscriptions,
  useCustomerSubscriptionPreview
} from "@libs/api/gateways/plt/plt-gateway.hooks";

import { SubscriptionEditFormFields } from "./SubscriptionEditFormFields";
import { SubscriptionItemProducts } from "./SubscriptionItems.types";
import {
  getAddSubscriptionsDtos,
  getSubscriptionsProducts
} from "./subscription-form.utils";
import { PreviewSubscriptionDialog } from "../subscription-preview/PreviewSubscriptionDialog";
import { SUB_FORM_ID } from "./subscription-form.constants";

interface SubscriptionFormProps {
  customerProducts: CustomerProductsDto;
  tenant: Tenant;
  id: string | undefined;
}

export interface SubscriptionFormValues {
  subscriptionProducts: SubscriptionItemProducts[];
  promotionCodeList?: string[];
  additionalPromotionCodeList?: string[];
}

export const SubscriptionForm: FunctionComponent<SubscriptionFormProps> = ({
  customerProducts,
  tenant,
  id
}) => {
  const isPreviewed = useRef<boolean>(false);
  const {
    mutateAsync: submitSubscription,
    error: submitError
  } = useAddCustomerSubscriptions(tenant.id);

  const {
    mutateAsync: getSubscriptionPreview,
    error: previewError,
    isLoading: isPreviewIsLoading,
    data: previewData,
    reset: resetPreviewData
  } = useCustomerSubscriptionPreview();

  const navigate = useNavigate();
  const subscription = customerProducts.subscriptionList.find(
    s => s.subscriptionId === id
  );

  const subscriptionProducts = subscription
    ? getSubscriptionsProducts(subscription).filter(s => !!s.quantity)
    : [];

  const handleSubmit = async (payload: AddSubscriptionDto[]) => {
    resetPreviewData();
    await submitSubscription(payload);
    navigate(`../../../${tenant.id}/sales-subscriptions`);
  };

  const onTrySubmit = async (values: SubscriptionFormValues) => {
    const payload = getAddSubscriptionsDtos({
      ...values,
      promotionCodeList: values.additionalPromotionCodeList
    });

    if (!isPreviewed.current) {
      isPreviewed.current = true;
      await getSubscriptionPreview(payload);
    } else {
      await handleSubmit(payload);
    }
  };

  return (
    <Form<SubscriptionFormValues>
      formId={SUB_FORM_ID}
      error={submitError}
      defaultValues={{
        subscriptionProducts,
        promotionCodeList: subscription?.promotionCodeList
      }}
      onSubmit={onTrySubmit}
      formStyles={{ root: { height: "100%", flexGrow: 1 } }}
      buttonsRootStyles={{ root: { marginTop: "auto" } }}
      hideButtons
    >
      <>
        <SubscriptionEditFormFields
          subscription={subscription}
          tenant={tenant}
        />
        {previewData && (
          <PreviewSubscriptionDialog
            isPreviewIsLoading={isPreviewIsLoading}
            previewError={previewError}
            previewData={previewData}
            onCancel={() => {
              isPreviewed.current = false;
              resetPreviewData();
            }}
          />
        )}
      </>
    </Form>
  );
};
