import React, { FunctionComponent } from "react";
import { ConfirmDialog, ConfirmDialogProps } from "@bps/fluent-ui";
import { PreviewSubscriptionAccordion } from "./PreviewSubscriptionAccordion";
import { HttpError } from "@bps/http-client";
import { SubscriptionPreviewDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { SUB_FORM_ID } from "../subscription-form/subscription-form.constants";

interface PreviewSubscriptionDialogProps
  extends Pick<ConfirmDialogProps, "onCancel"> {
  previewError: HttpError | null;
  isPreviewIsLoading: boolean;
  previewData: SubscriptionPreviewDto[];
}
export const PreviewSubscriptionDialog: FunctionComponent<PreviewSubscriptionDialogProps> = ({
  isPreviewIsLoading,
  previewData,
  previewError,
  onCancel
}) => {
  return (
    <ConfirmDialog
      minWidth={650}
      dialogContentProps={{
        title: "Subscription update review",
        subText: "Would you like to confirm the changes to your subscription?"
      }}
      confirmButtonProps={{
        children: "Confirm",
        // used to submit form outside for <form> tag/element
        form: SUB_FORM_ID,
        type: "submit"
      }}
      cancelButtonProps={{ children: "Cancel" }}
      hidden={false}
      onConfirm={() => {}}
      onCancel={onCancel}
    >
      <PreviewSubscriptionAccordion
        error={previewError}
        isLoading={isPreviewIsLoading}
        subscriptions={previewData}
      />
    </ConfirmDialog>
  );
};
