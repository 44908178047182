import { FunctionComponent } from "react";
import { HttpError } from "@bps/http-client";
import { SubscriptionPreviewDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  Accordion,
  CenteredLargeSpinner,
  MessageBar,
  MessageBarType
} from "@bps/fluent-ui";
import { getSubscriptionProductsPreview } from "./preview-subscription.utils";
import { PreviewSubscriptionAccordionItem } from "./PreviewSubscriptionAccordionItem";

interface PreviewSubscriptionAccordionProps {
  isLoading?: boolean;
  error: HttpError | null;
  subscriptions?: SubscriptionPreviewDto[];
}
export const PreviewSubscriptionAccordion: FunctionComponent<PreviewSubscriptionAccordionProps> = ({
  isLoading,
  subscriptions,
  error
}) => {
  if (isLoading) return <CenteredLargeSpinner />;
  if (error)
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        {error.message}
      </MessageBar>
    );

  if (subscriptions && subscriptions.length) {
    const previewSubscriptionProducts = getSubscriptionProductsPreview(
      subscriptions
    );
    return (
      <Accordion multiple contentBackground disableAnimation>
        {previewSubscriptionProducts.map(subscriptionProduct => (
          <PreviewSubscriptionAccordionItem
            key={subscriptionProduct.salesProductId}
            data={subscriptionProduct}
          />
        ))}
      </Accordion>
    );
  }

  return null;
};
