import { SubscriptionPreviewDto } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { PreviewSubscriptionProductData } from "./preview-subscription.types";

export const getSubscriptionProductsPreview = (
  subscriptions: SubscriptionPreviewDto[]
): PreviewSubscriptionProductData[] => {
  return subscriptions.reduce<PreviewSubscriptionProductData[]>(
    (accumulator, subscription) => {
      subscription.items.forEach(item => {
        // Check if the product already exists in the accumulator
        const existingProductIndex = accumulator.findIndex(
          product =>
            product.salesProductFamily === item.salesProductFamily &&
            product.salesProductId === item.salesProductId
        );

        if (existingProductIndex >= 0) {
          // Existing product found; update its totals
          const existingProduct = accumulator[existingProductIndex];
          existingProduct.totalQuantity += item.quantity ?? 0;
          existingProduct.totalAmount += item.amount;

          // Update child tenant information
          const existingChildTenant = existingProduct.childTenants.find(
            tenant => tenant.childTenantId === subscription.childTenantId
          );
          if (existingChildTenant) {
            existingChildTenant.quantity += item.quantity ?? 0;
            existingChildTenant.amount += item.amount;
          } else {
            // If the child tenant doesn't exist, add it
            existingProduct.childTenants.push({
              childTenantId: subscription.childTenantId,
              quantity: item.quantity ?? 0,
              amount: item.amount
            });
          }
        } else {
          // New product, create and push to the accumulator
          accumulator.push({
            salesProductFamily: item.salesProductFamily,
            salesProductId: item.salesProductId,
            totalAmount: item.amount,
            totalQuantity: item.quantity ?? 0,
            childTenants: [
              {
                childTenantId: subscription.childTenantId,
                quantity: item.quantity ?? 0,
                amount: item.amount
              }
            ]
          });
        }
      });

      return accumulator;
    },
    []
  );
};
